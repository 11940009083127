import React from 'react';
import "./footer.scss";
import { Instagram,Twitter } from '@mui/icons-material';
import { Link } from 'react-router-dom';


export default function Footer(){
    return(
        <div className='footer'>
            <div>
            <a href="https://yalzapp.com/#/home" target="blank">
            <h4>©2022 Yalzapp. All rights reserved </h4>

            </a>

            </div>
            <div className='social-media'>
            <a href="https://instagram.com/whoislyingapp?igshid=YmMyMTA2M2Y=" target="blank">
            <Instagram/>

            </a>
            <a href="https://twitter.com" target="blank">
            <Twitter/>

            </a>
            <Link to='/privacy-policy' className='privacy-policy'>Privacy policy</Link>
            <Link to='/terms-and-conditions' className='privacy-policy'>Terms and Conditions</Link>


            </div>

        </div>
    )
}