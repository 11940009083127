import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './fonts/ArticulatCF/ArticulatCF-Bold.otf';
import { BrowserRouter } from 'react-router-dom';
import { Routes,Route } from 'react-router-dom';
import PrivacyPolicy from './privacy-policy';
import TermsAndConditions from "./terms-and-conditions";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
      </Routes>

    </BrowserRouter>
  </React.StrictMode>
);


