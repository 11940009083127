import "./App.scss";
import Navbar from "./components/navbar/Navbar";
import { Apple } from "@mui/icons-material";
import GooglePlayLogo from "./image/google-play-logo.png";
import HeroImage from "./image/hero-image.png";
import HeroImageTwo from "./image/hero-image-edit.png";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <div className="app">
      <Navbar />
      <div className="main-page">
        <div className="first-row" id="about">
          <div className="first-row-left">
            <h1 className="header">
              {/* It's simple,find out who's lying. */}
              IT'S SIMPLE, FIND OUT WHO'S LYING.
            </h1>
            <p className="paragraph">
              Get ready for an addictive game of deception and mystery solving.
              {/* Join Beta Program! */}
            </p>
            <div className="buttons">
              <a
                // href="https://testflight.apple.com/join/7SOJGQqp"
                href="https://apps.apple.com/ng/app/who-is-lying/id1632287752"
                target="blank"
              >
                <button>
                  <Apple className="apple-logo" />
                  <div className="apple-text">
                    Download on the
                    <span className="large-button-text"> App store</span>
                  </div>
                </button>
              </a>
              <button className="google-play-button">
                <img
                  src={GooglePlayLogo}
                  alt="google-play-logo"
                  className="google-play-logo"
                />
                <div className="google-play-text">
                  Get it on
                  <span className="large-button-text">Google play</span>
                </div>
              </button>
            </div>
          </div>
          <div className="first-row-right">
            <img src={HeroImage} alt="hero-pic" className="hero-pic" />
          </div>
        </div>
        <div className="second-row" id="online">
          <div className="second-row-right">
            <h1 className="header">
              {/* FOR EVERYONE. ANYWHERE. */}
              HAVE FUN, ENJOY PLAYING.
            </h1>
          </div>

          <div className="second-row-left">
            <img src={HeroImageTwo} alt="hero-pic-two" className="hero-pic" />
          </div>
        </div>
      </div>{" "}
      <Footer />
    </div>
  );
}

export default App;
