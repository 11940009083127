import React from 'react';
import "./navbar.scss";
import { DensityMedium, Clear } from '@mui/icons-material';
import NavLogo from "../../image/logo-horizontal.png";
import { HashLink } from 'react-router-hash-link';



export default function Navbar(){
    const [isMobile,setIsMobile] = React.useState(false);
    function toggleIsMobile(){
        setIsMobile(prevMobile=>!prevMobile)
    }
    return(
        <div className='navbar'>
            <div className='logo'>
                <img src={NavLogo} alt="whoislying" className='nav-logo'/>

            </div>
            <div className='navbar-right-container'>
            <ul className='navbar-right'>
                <HashLink smooth to="#about" className='navlink' activeStyle={{ color: 'red' }}>
                <li>About</li>

                </HashLink>
                <HashLink smooth to="#online" className='navlink'>
                <li>Gameplay
                    {/* Display */}
                </li>

                </HashLink>
                {/* <HashLink to="#author" className='navlink'>
                <li>Author</li>

                </HashLink> */}
            </ul>

            </div>
            <div className='navbar-right-mobile'>
            
            {isMobile? 
            <Clear className="hamburger-icon" onClick={toggleIsMobile}/>:
            <DensityMedium className='hamburger-icon' onClick={toggleIsMobile}/>
            }

            </div>
            
            {isMobile? <div>
                <ul className='mobile-slidedown'>
                <HashLink smooth to="#about" className='navlink' activeStyle={{ color: 'red' }}>
                <li className='mobile-links'>About</li>

                </HashLink>
                <HashLink smooth to="#online" className='navlink'>
                <li className='mobile-links'>Gameplay</li>

                {/* </HashLink>
                <HashLink to="#author" className='navlink'>
                <li className="mobile-links">Author</li> */}

                </HashLink>
                </ul>
            </div>:""}

        </div>
    )
}