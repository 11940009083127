import React from "react";
import "./privacy-policy.scss";
import { ArrowBackIos } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  return (
    <div className="privacy-policy-section">
      

    <div className="privacy-policy">
      <Link to="/">
      <ArrowBackIos className="back-arrow"/>

      </Link>
      <h1>Privacy Policy</h1>

      <p>
        This privacy policy ("Policy") describes how Yalzapp
        ("Company") collects, uses, and shares personal
        information of users ("you," "your") of our social deduction game
        ("Game").
      </p>

      <h2>Information We Collect</h2>

      <p>
        We collect personal information that you voluntarily provide to us when
        you sign in to the Game. This information may include your name, email
        address, birthday, and interests. We do not collect any personal
        information if you choose not to sign in to the Game.
      </p>

      <h2>How We Use Your Information</h2>

      <p>
        We use the personal information we collect from you to provide and
        improve the Game, to personalize your experience, and to communicate
        with you about the Game and other related services. We may also use your
        information for research and analytics purposes, and to enforce our
        Terms of Service.
      </p>

      <h2>Sharing Your Information</h2>

      <p>
        We may share your personal information with our partners, vendors, and
        other service providers who provide services to us or on our behalf,
        such as hosting, analytics, and customer support. We may also share your
        information if we believe it is necessary to comply with a legal
        obligation, to protect our rights or the rights of others, or to detect,
        prevent, or otherwise address fraud, security, or technical issues. We
        will not sell your personal information to any third party.
      </p>

      <h2>Data Retention and Security</h2>

      <p>
        We will retain your personal information for as long as your account is
        active or as needed to provide you with the Game and related services.
        We will take reasonable steps to protect the security of your personal
        information, including the use of industry-standard encryption and
        security protocols. However, please note that no method of transmission
        over the internet or method of electronic storage is completely secure,
        and we cannot guarantee the absolute security of your personal
        information.
      </p>

      <h2>Changes to Our Privacy Policy</h2>

      <p>
        We may update this Policy from time to time. If we make any material
        changes to this Policy, we will notify you through the Game or by email.
        We encourage you to periodically review this Policy for the latest
        information on our privacy practices.
      </p>

      <h2>Contact Us</h2>

      <p>
        If you have any questions or concerns about this Policy or our privacy
        practices, please contact us at contact@yalzapp.com.
      </p>
    </div>
    </div>
  );
}
